import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,

})
export class LogoComponent implements OnInit {

  @Input() height = 53;
  @Input() width = 222;
  @Input() christmas = false;

  constructor() { }

  ngOnInit(): void {
  }

}
