@if (christmas) {
  <img src="assets/images/christmas/logo.svg">
} @else {

    <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 608.46 149.65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#019c9c" d="M29.58,59.14l-4.47-8.17c-3.92-7.16-9.77-13.01-16.93-16.93L0,29.58l8.18-4.48c7.16-3.92,13.01-9.77,16.93-16.93L29.58,0l4.47,8.17c3.92,7.16,9.77,13.01,16.93,16.93l8.18,4.48-8.19,4.47c-7.15,3.91-13.01,9.77-16.92,16.93l-4.47,8.17Zm-12.21-29.57c4.74,3.35,8.86,7.47,12.21,12.21,3.35-4.74,7.47-8.86,12.21-12.21-4.74-3.35-8.86-7.48-12.21-12.21-3.35,4.74-7.47,8.86-12.21,12.21Z"/>
      <g>
        <path fill="currentColor" d="M82.12,40.89c-1.56-.2-3.14-.34-4.76-.34-20.56,0-37.28,16.72-37.28,37.28v40.73h12.81v-40.73c0-13.51,10.95-24.47,24.47-24.47,1.63,0,3.22,.17,4.76,.47v-12.94Z"/>
        <path fill="currentColor" d="M456.67,40.96c-1.56-.2-3.14-.34-4.76-.34-20.56,0-37.28,16.72-37.28,37.28v40.73h12.81v-40.73c0-13.51,10.95-24.47,24.47-24.47,1.63,0,3.22,.17,4.76,.47v-12.94Z"/>
        <g>
          <path fill="currentColor" d="M97.72,84.91c1.96,14.23,13.41,22.89,30.74,22.89,11.28,0,21.09-3.6,28.29-10.46h.65v14.06c-6.87,5.56-17.33,8.83-29.27,8.83-26.98,0-44.47-15.86-44.47-39.89s16.51-39.89,39.89-39.89,38.75,14.55,38.75,36.79v7.69H97.72Zm0-11.61h51.66c-1.96-12.43-11.77-20.44-25.67-20.44-13.08,0-23.38,8.17-25.99,20.44Z"/>
          <path fill="currentColor" d="M222.3,46.32v14.71h-.65c-5.23-5.4-13.4-8.17-24.03-8.17-9.81,0-16.19,4.58-16.19,11.44,0,5.56,4.09,8.17,16.84,8.83,20.93,1.15,28.61,8.17,28.61,23.22s-11.61,23.87-30.25,23.87c-12.75,0-22.73-2.78-29.1-8.01v-15.04h.65c6.21,7.03,15.7,10.63,27.96,10.63,10.46,0,17-4.42,17-11.44,0-6.21-4.25-9.16-17.98-9.97-20.11-1.31-27.47-7.68-27.47-21.58s11.77-24.36,29.59-24.36c10.14,0,19.29,2.12,25.01,5.89Z"/>
          <path fill="currentColor" d="M315.82,80.33c0,23.22-16.19,39.89-38.42,39.89-12.26,0-21.75-4.25-27.8-11.44h-.33v40.87h-13.9V42.07h11.77l.33,12.1h.33c5.89-8.5,16.19-13.73,29.59-13.73,22.24,0,38.42,16.68,38.42,39.89Zm-14.06,0c0-16.19-10.96-27.47-26.49-27.47s-26.49,11.28-26.49,27.47,10.79,27.47,26.49,27.47,26.49-11.28,26.49-27.47Z"/>
          <path fill="currentColor" d="M389.22,118.59l-.49-12.1h-.33c-5.72,8.5-16.19,13.73-29.59,13.73-22.24,0-38.26-16.68-38.26-39.89s16.02-39.89,38.26-39.89c13.41,0,23.87,5.23,29.59,13.9h.33l.49-12.26h11.77V118.59h-11.77Zm-1.63-38.26c0-16.19-10.96-27.47-26.49-27.47s-26.49,11.28-26.49,27.47,10.79,27.47,26.49,27.47,26.49-11.28,26.49-27.47Z"/>
          <path fill="currentColor" d="M480.11,118.59h-13.9V4.14h13.9V75.43l34.34-33.35h18.15l-39.24,37.44,40.87,39.07h-18.97l-35.15-34.17v34.17Z"/>
          <path fill="currentColor" d="M543.88,84.91c1.96,14.23,13.41,22.89,30.74,22.89,11.28,0,21.09-3.6,28.29-10.46h.65v14.06c-6.87,5.56-17.33,8.83-29.27,8.83-26.98,0-44.47-15.86-44.47-39.89s16.51-39.89,39.89-39.89,38.75,14.55,38.75,36.79v7.69h-64.58Zm0-11.61h51.66c-1.96-12.43-11.77-20.44-25.67-20.44-13.08,0-23.38,8.17-25.99,20.44Z"/>
        </g>
      </g>
    </svg>

}
